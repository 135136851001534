import { useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Env } from "classes"
import { GlobalContext } from "context/context/GlobalContext"
import OrderInfoPrint from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderInfoPrint"
import OrderDetailProductListGrid from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailProductListGrid"
import OrderDetailSeriesList from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailSeriesList"
import OrderDetailTraces from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailTraces"
import { formatDate, formatManualTime } from "helpers/FormatDateHelper"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { getStatusTranslateValue } from "overrides/theme/entel/base/helpers/status_helper"
import { useEnv } from "hooks"
import Modal from "components/Commons/Modal/Modal"
import OrderDetailModalAction from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailModalAction"
import {
  updateShippingGroupStateCustom,
  updateShippingGroupStateSelectPicking,
  updateShippingGroupStateDeliveredInSourceCancel,
} from "overrides/theme/entel/base/services/ShippingGroupService"
import { IconCopy, IconReload } from "theme/common/Icon.styled"
import {
  reason_cancel_body,
  reason_cancel_delivered_source_body,
} from "overrides/theme/entel/base/helpers/cancel_reasons"
import AlertDetailOptionStatus from "overrides/theme/entel/base/components/OrderDetail/AlertDetailOptionStatus/AlertDetailOptionStatus"
import cn from "classnames"

const OrderDetailsBtnAction = (props: any) => {
  const { action, index, event_sg_action, t, permissions, status, set_reason_cancel, items_selialized_completed } =
    props
  const cond = permissions.get_permission(`status_${status}`, `action-${action.action}`)
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)
  const _translates: any = translatesObj

  const template = (
    <>
      <Modal ref={refModal}>
        <OrderDetailModalAction
          action={action}
          confirmAction={() => event_sg_action(action)}
          close={() => refModal.current?.close()}
          set_reason_cancel={set_reason_cancel}
          items_selialized_completed={items_selialized_completed}
          status={status}
        />
      </Modal>
      <button
        key={index}
        className={cn(`order__main__detail__action__action order__main__detail__action__${action.action}`, {
          order__main__detail__action__cancel: Boolean(action.action === "shipped"),
        })}
        onClick={() => refModal.current?.open()}
      >
        {status === "IN_TRANSIT" && action.action === "cancel" ? "Retornar y cancelar" : t(_translates[action.action])}
      </button>
    </>
  )

  return cond && template
}

const OrderDetails = (props: any) => {
  const { t } = useTranslation()
  const { data, set_loading, user, update_info_detail } = props
  const { shippingtype }: any = useParams()
  const { permissions } = useEnv()
  const { notification, errorHander } = useContext(GlobalContext)
  const [reason_cancel, set_reason_cancel] = useState<string>()
  const [series, set_series] = useState<Array<any>>([])
  let itemsOrder = data?._sg?.logisticPlan?.items

  const items_selialized_completed = () => {
    if (Boolean(itemsOrder?.filter((_it: any) => Boolean(_it?.hasOwnProperty("series"))).length)) {
      const items_to_eval = itemsOrder?.map((item: any) => item?.series?.some((_it: any) => !_it?.valid_serie))
      return Boolean(series.length === items_to_eval.filter(Boolean).length)
    }
    return Boolean(series.length === itemsOrder.length)
  }

  const currentState = data?._sg?._omnixStateMachine?.currentState?.state?.name

  const [actions, set_actions] = useState([])

  useEffect(() => {
    const _actions = Env.theme.entel.sg[shippingtype.toUpperCase()].status_actions[currentState] || []
    set_actions(_actions)
    //eslint-disable-next-line
  }, [])

  const event_sg_action = async (event: any) => {
    const action = event.action
    // let shipping_type_action = shippingtype

    try {
      set_loading(true)

      let data_req = {
        entityId: data?._sg?.salesChannelId,
        action: action.toUpperCase(),
        custom: {
          executer: {
            id: user?.email,
            name: "Omnix Store",
          },
        },
      }

      if (action === "pick") {
        Object.assign(data_req.custom, {
          description: "Picking de producto",
          pickingId: data?._sg?.pickingId,
          items: series,
        })
      }

      if (action === "delivered_in_source") {
        Object.assign(data_req.custom, {
          description: "Pedido retornado en tienda",
          sourceId: data?._sg?.source?.id,
          location: {
            latitude: "",
            longitude: "",
          },
        })
        Object.assign(data_req, {
          customCancel: {
            reason: "MAX_DELIVERY_ATTEMPT",
            description: "Cancelar SG por maximo intento de entrega",
          },
        })
      }

      if (action === "cancel" && reason_cancel) {
        if (currentState === "IN_TRANSIT") {
          data_req = reason_cancel_delivered_source_body(reason_cancel, data?._sg, user?.email)
        } else {
          data_req = reason_cancel_body(reason_cancel, data?._sg, user?.email)
        }
      }

      if (action === "select") {
        await updateShippingGroupStateSelectPicking(data_req)
      } else {
        if (currentState === "IN_TRANSIT" && action === "cancel") {
          await updateShippingGroupStateDeliveredInSourceCancel(data_req)
        } else {
          await updateShippingGroupStateCustom(data_req)
        }
      }
      // set_loading(false)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Orden modificada exitosamente!",
          title: `${t(`Acción aplicada`)}: ${t(`${action}`)}`,
          type: "success",
        },
      })
      setTimeout(() => update_info_detail(), 5000)
    } catch (e: any) {
      errorHander?.dispatch({ hasError: true, code: 500 })
    }
  }

  const isExpress = Boolean(data?._sg?.deliveryType === "EXPRESS")

  const _slotDelivery = {
    initial: isExpress ? data?._sg?.logisticPlan?.baseDate : data?._sg?.slotDelivery?.initialHour,
    final: isExpress ? data?._sg?.logisticPlan?.delivery : data?._sg?.slotDelivery?.finalHour,
  }

  const currentStatusTag = data?._sg?._omnixStateMachine?.currentState?.state?.id?.toUpperCase()

  const copyToclipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: text,
            title: `Copiado al portapapeles`,
            type: "success",
          },
        })
      })
      .catch(function (error) {
        // Error callback
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: JSON.stringify(error),
            title: "ERROR",
            type: "danger",
          },
        })
      })
  }

  const ButtonReloadInfo = () => (
    <div className="flex flex-row justify-end items-end">
      <div
        className="order__main__detail__action__action order__main__detail__button_reload_info"
        onClick={update_info_detail}
      >
        <p>Actualizar información</p>
        <IconReload style={{ fontWeight: "bold" }} />
      </div>
    </div>
  )

  const courierName =
    data?._order?.logisticPlan?.sources?.length &&
    data?._order?.logisticPlan?.sources[0]?.route?.length &&
    data?._order?.logisticPlan?.sources[0]?.route[0].courier?.name

  const commune =
    data?._order?.logisticPlan?.sources?.length &&
    data?._order?.logisticPlan?.sources[0]?.route?.length &&
    data?._order?.logisticPlan?.sources[0]?.route[0].target?.data?.criteria3

  const template = (
    <section className="page__order">
      <article className="order__main">
        {permissions.get_permission("details", "block-info") && (
          <section className="order__main__detail">
            <div className="order__main__detail__title_container">
              <h2>{t(`Orden # ${data?._sg?.salesChannelId}`)}</h2>
              <IconCopy style={{ cursor: "pointer" }} onClick={() => copyToclipboard(data?._sg?.salesChannelId)} />
            </div>

            <article className="order__main__detail__summary">
              {data?._sg?.source?.name && (
                <h3>
                  {t("BODEGA/TIENDA")} {data?._sg?.source?.name} {` (${data?._sg?.source?.id})`}
                </h3>
              )}
              <div>
                <p>{t("ID EOC")}:</p>
                <p>
                  <strong>{data?._sg?.salesChannelId}</strong>
                </p>
              </div>
              {data?._sg?.source?.shippingAddress && (
                <>
                  <h3>
                    {t("Cliente")} {data?._sg?.source?.shippingAddress?.contact?.name}
                  </h3>
                  {data?._sg?.source?.shippingAddress?.contact?.id && (
                    <div>
                      <p>{t("Documento")}:</p>
                      <p>
                        <strong>{data?._sg?.source?.shippingAddress?.contact?.id}</strong>
                      </p>
                    </div>
                  )}
                  {Object.values(data?._sg?.source?.shippingAddress).map((info: any, _index: any) => {
                    if (typeof info === "string") return <p key={_index}>{info}</p>
                    return null
                  })}
                  {data?._sg?.target?.source?.id && (
                    <div>
                      <p>{t("Destino")}:</p>
                      <p>
                        <strong>{data?._sg?.target?.source?.id}</strong>
                      </p>
                    </div>
                  )}
                </>
              )}

              {data?._sg?.target?.customer?.name && <h4>{data?._sg?.target?.customer?.name}</h4>}

              {data?._sg?.target?.source?.id && data?._sg?.shippingType === "SP" && (
                <div>
                  <p>{t("Punto de retiro")}:</p>
                  <p>
                    <strong>{data?._sg?.target?.source?.id}</strong>
                  </p>
                </div>
              )}

              {data?._sg?.target?.customer?.id && (
                <div>
                  <p>{t("Documento")}:</p>
                  <p>
                    <strong>{data?._sg?.target?.customer?.id}</strong>
                  </p>
                </div>
              )}

              {data?._sg?.target?.customer?.email && (
                <div>
                  <p>{t("Email")}:</p>
                  <p>
                    <strong>{data?._sg?.target?.customer?.email}</strong>
                  </p>
                </div>
              )}

              <div>
                <p>{t("Estado actual")}:</p>
                <p>
                  <strong>{getStatusTranslateValue(currentStatusTag, shippingtype.toUpperCase())?.title}</strong>
                </p>
              </div>
              <div>
                <p>{t("Tipo de de despacho")}:</p>
                <p>
                  <strong>{t(`${data?._sg?.shippingType}`)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Fecha estimada de entrega")}:</p>
                <p>
                  <strong>{formatDate(data?._order?.estimatedDeliveryDate)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Nombre del cliente")}:</p>
                <p>
                  <strong>{t(`${data?._sg?.target?.name}`)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Tipo de entrega")}:</p>
                <p>
                  <strong>{t(`${data?._order?.deliveryType}`)}</strong>
                </p>
              </div>
              <div>
                <p>{t("RUT del cliente")}:</p>
                <p className="flex flex-row gap-2 items-center">
                  <strong>{t(`${data?._order?.customer?.id}`)}</strong>
                  <IconCopy
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToclipboard(data?._order?.customer?.id || "")}
                  />
                </p>
              </div>
              <div>
                <p>{t("Courier")}:</p>
                <p>
                  <strong>{courierName}</strong>
                </p>
              </div>
              <div>
                <p>{t("Fecha de creación")}:</p>
                <p>
                  <strong>{formatDate(data?._order?.creationDate)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Hora de creación")}:</p>
                <p>
                  <strong>{formatDate(data?._order?.creationDate, "hh:ss")}</strong>
                </p>
              </div>
              <div>
                <p>{t("Fecha de despacho")}:</p>
                <p>
                  <strong>{formatDate(data?._order?.estimatedDeliveryDate)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Portabilidad si/no")}:</p>
                <p>
                  <strong>{data?._order?.orderType === "Portabilidad" ? "Si" : "No"}</strong>
                </p>
              </div>
              <div>
                <p>{t("Dirección")}:</p>
                <p>
                  <strong>{`${data?._order?.shippingAddress?.addressName} ${data?._order?.shippingAddress?.addressNumber}`}</strong>
                </p>
              </div>
              <div>
                <p>{t("Comuna de destino")}:</p>
                <p>
                  <strong>{commune}</strong>
                </p>
              </div>
              <div>
                <p>{t("Slot horario asignado")}:</p>
                <p>
                  <strong>{formatManualTime(_slotDelivery?.initial)}</strong> /{" "}
                  <strong>{formatManualTime(_slotDelivery?.final)}</strong>{" "}
                  <strong>{data?._order?.slotDelivery?.slotType}</strong>
                </p>
              </div>
              {data?._sg?.pickingId && (
                <div>
                  <p>{t("Picking ID")}:</p>
                  <p>
                    <strong>{data?._sg?.pickingId}</strong>
                  </p>
                </div>
              )}

              <div className="order__main__detail__action">
                {!!actions &&
                  actions?.map((action: any, index: number) => (
                    <OrderDetailsBtnAction
                      action={action}
                      index={index}
                      t={t}
                      permissions={permissions}
                      event_sg_action={event_sg_action}
                      status={currentState}
                      key={index}
                      set_reason_cancel={set_reason_cancel}
                      items_selialized_completed={items_selialized_completed()}
                    />
                  ))}
              </div>
            </article>
          </section>
        )}
        <span className="show_desktop_and_hide_mobile">
          <div className="w-full flex flex-col gap-4">
            {permissions.get_permission("details", "block-tag") && (
              <OrderInfoPrint courierName={courierName} data={data} />
            )}
            <ButtonReloadInfo />
          </div>
          <div className="w-full flex flex-col justify-start align-start p-4">
            {shippingtype.toUpperCase() === "HD" ? <AlertDetailOptionStatus status={currentStatusTag} /> : null}
          </div>
        </span>
      </article>

      {permissions.get_permission("details", "block-products") && (
        <OrderDetailProductListGrid data={data} series={{ state: series, dispatch: set_series }} />
      )}

      <span className="show_mobile_and_hide_desktop">
        {permissions.get_permission("details", "block-tag") && <OrderInfoPrint courierName={courierName} data={data} />}
        <ButtonReloadInfo />
      </span>

      {permissions.get_permission("details", "block-series") && <OrderDetailSeriesList data={data} />}

      {permissions.get_permission("details", "block-traces") && <OrderDetailTraces data={data} />}
    </section>
  )

  return template
}

export default OrderDetails
